<template>
  <div class="flex items-center gap-x-1" :class="layoutSize">
    <StarsRating :rating="rating" />
    <p v-if="layout === 'info'" class="font-medium">{{ rating }}</p>
    <p v-else class="text-xs md:text-base">{{ rating }} auf DocFinder</p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  rating: number;
  layout?: "card" | "info";
}>();

const layoutSize = computed(() => {
  if (props.layout === "info") {
    return "text-lg md:text-xl xl:text-2xl";
  }

  return "";
});
</script>

<style scoped></style>
